<template>
  <lay-out>
    <router-view :key="$route.path" />
  </lay-out>
</template>

<script>
import LayOut from './components/layout/LayOut';


export default {
  name: 'App',
  components: {
    LayOut
  }
}
</script>
