<template>
  <nav class="navbar navbar-expand-sm static-top jglp-navbar">
    <div class="container">
      <a class="navbar-brand" href="./"
        ><img
          src="https://apidata.ch.jglp.ch/uploads/medium_jglp_schweiz_logo_web_4bdb5de878.jpg"
          style="width: 100px"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a
              class="jglp-nav-link jglp-nav-link active"
              aria-current="page"
              href="/"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a class="jglp-nav-link" href="./design-guideline">Design Guideline</a>
          </li>
          <li class="nav-item">
            <a class="jglp-nav-link" href="./release-notes">Release Notes</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="scss">
.jglp-navbar {
  background-color: white;
  box-shadow: 0px 1px 10px #cecece;
}

.jglp-nav-link {
  display: block;
  font-weight: bold;
  padding: 5px;
  padding-left: 10px;
  font-size: var(--bs-nav-link-font-size);
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
</style>