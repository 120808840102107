<template>
  <div class="layout">
    <nav-bar
      v-if="!['app'].includes($route.query.output)"
    />
    <main class="main">
      <slot />
    </main>
  </div>
</template>

<script>

import NavBar from './NavBar.vue';

export default {
  name: 'LayOut',
  components: { NavBar },
  data() {
    return {
    }
  },
}
</script>

