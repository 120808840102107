import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../pages/Home.vue'),
  },
  {
    path: '/design-guideline',
    name: 'Design Guideline',
    component: () => import('../pages/DesignGuideline.vue'),
  },
  {
    path: '/release-notes',
    name: 'Release Notes',
    component: () => import('../pages/ReleaseNotes.vue'),
  },
]
  
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

  
export default router