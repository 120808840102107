import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import Ybug from 'ybug-vue';


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'
import './scss/main.scss';

export const app = createApp(App);

app.use(Ybug, {
    id: 'mca2b3rvvhzhyktfm90j',
    /* optional options */
    settings: {
        language_override: 'de',
    }
});
app.use(router);
app.use(store);
app.use()
app.mount('#app');
